<template>
  <div class="outer">
    <div class="hl-header">
      <div class="hl-search">
        <div class="hl-title">房间管理</div>
      </div>
    </div>
    <div class="searchAll">
      <el-card>
        <!-- 一个表单 -->
        <el-form :inline="true" :model="condition">
          <el-form-item label="房型" prop="roomType">
            <el-select
              class="text"
              filterable
              style="width: 100%"
              v-model="condition.roomType"
              placeholder="请选择房型"
            >
              <el-option
                v-for="item in roomTypeList"
                :key="item.id"
                :label="item.typeName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="区域" prop="area">
            <el-select
              class="text"
              filterable
              style="width: 100%"
              v-model="condition.area"
              placeholder="请选择区域"
            >
              <el-option
                v-for="item in roomAreaList"
                :key="item.id"
                :label="item.areaName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="楼层" prop="foor">
            <el-select
              class="text"
              filterable
              style="width: 100%"
              v-model="condition.foor"
              placeholder="请选择楼层"
            >
              <el-option
                v-for="item in foorList"
                :key="item.id"
                :label="item.floorName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="房号" prop="roomType">
            <el-input v-model="condition.roomNo"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              class="addIdType2"
              type="primary"
              @click="getFounderList()"
              >查询</el-button
            >
            <el-button class="addIdType3" type="primary" @click="reSearch()"
              >重置</el-button
            >
          </el-form-item>
          <el-form-item style="position: absolute; right: 20px">
            <el-button
              type="primary"
              v-if="chuangjian"
              @click="addIdType(0, null)"
              class="addIdType"
              >+ 房间</el-button
            >
            <el-button
              class="addIdType"
              v-if="shanchu"
              type="primary"
              @click="bechChange"
              >批量删除</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
    </div>

    <div class="hl-content">
      <!-- <div class="mokuai">房间管理列表</div> -->
      <el-table
        class="hl-table"
        ref="table"
        :data="tableData"
        height="90%"
        :header-cell-style="{ background: '#F4F5F9', color: '#000000' }"
        @selection-change="handleSelectionChange"
        @select="dialogCheck"
        @row-click="rowClick"
      >
        <el-table-column type="selection"> </el-table-column>
        <el-table-column label="房型">
          <template slot-scope="scope">
            <span>{{ scope.row.roomTypeName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="房号">
          <template slot-scope="scope">
            <span>{{ scope.row.roomNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="区域">
          <template slot-scope="scope">
            <span>{{ scope.row.areaName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="楼层 ">
          <template slot-scope="scope">
            <span>{{ scope.row.foorName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="房态">
          <template slot-scope="scope">
            <span>{{ scope.row.roomStatusName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="MAC地址" width="200">
          <template slot-scope="scope">
            <span class="copy" @click="copyMac(scope.row.routerUrl)">{{
              scope.row.routerUrl
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="状态">
          <template slot-scope="scope">
            <div class="zt">
              <div v-if="scope.row.online == 0">
                <span>离线</span>
              </div>
              <div v-if="scope.row.online == 1">
                <span>在线</span>
              </div>
              <div v-if="scope.row.online == 2">
                <span>故障</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="小度数量">
          <template slot-scope="scope">
            <span class="xdNum" @click="xdList(scope.row)">{{
              scope.row.xiaoduNum
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <div class="cz">
              <div class="qidong">
                <el-button
                  v-if="xiugai"
                  type="text"
                  @click="edit(scope.$index, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  style="color: red"
                  type="text"
                  @click="del(scope.row)"
                  >删除</el-button
                >
              </div>
              <el-button
                v-if="manage"
                class="cz-button"
                size="mini"
                @click="setMange(scope.row)"
                >设备管理</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @size-change="onPaginationSize"
          @current-change="onPaginationIndex"
          :current-page="condition.page"
          :page-size="condition.num"
          layout=" prev, pager, next, jumper,total"
          :total="totolNums"
        ></el-pagination>
      </div>
    </div>

    <addModify ref="addModify" />
    <disableModify ref="disableModify" />
    <!-- 删除 -->
    <el-dialog title="提示" :visible.sync="deleteVisible" width="30%">
      <el-input
        placeholder="请输入删除指示"
        v-model="todelete"
        style="width: 100%"
        maxlength="10"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 批量删除 -->
    <el-dialog title="批量删除" :visible.sync="pldeleteVisible" width="30%">
      <el-input
        placeholder="请输入删除指示"
        v-model="todelete"
        style="width: 100%"
        maxlength="10"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pldeleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="psdeleteSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import { querySelectAllUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";
import addModify from "@/views/room/addModify.vue";
import disableModify from "@/views/room/disableModify.vue";
export default {
  data() {
    return {
      tableData: [],
      list: [],
      hotelList: [],
      roomStatusList: [],
      input: "",
      status: 0,
      versionCode: "",
      versionName: "",
      statusRemark: "",
      totolNums: 2,
      condition: {
        offset: 1,
        num: 10,
      },
      chuangjian: false,
      xiugai: false,
      shanchu: false,
      manage: false,
      roomTypeList: [],
      foorList: [],
      roomAreaList: [],
      todelete: "",
      deleteVisible: false,
      pldeleteVisible: false,
      xiaodu: false,
      row: {},
      chooseList: [],
    };
  },
  components: {
    addModify,
    disableModify,
  },
  created() {
    if (!this.power.checkedpower("0305004")) {
      // 在PMS管理系统里面-获取权限
      this.$router.push("power");
    }
    // 创建
    this.chuangjian = this.power.checkedpower("0305001");
    // 修改
    this.xiugai = this.power.checkedpower("0305002");
    // 删除，禁用
    this.shanchu = this.power.checkedpower("0305003");
    this.manage = this.power.checkedpower("0305005");
    this.xiaodu = this.power.checkedpower("0305010");

    this.getFoorList();
    this.getRoomTypeList();
    this.getRoomAreaList();
    if (this.$store.state.roomTableList.length > 0) {
      this.tableData = this.$store.state.roomTableList;
      this.totolNums = this.$store.state.roomTableTotal;
    } else {
      this.getFounderList();
    }
  },
  methods: {
    copyMac(data) {
      let OrderNumber = data;
      let newInput = document.createElement("input");
      newInput.value = OrderNumber;
      document.body.appendChild(newInput);
      newInput.select();
      document.execCommand("Copy");
      newInput.remove();
      this.$message({
        type: "success",
        message: "复制成功",
      });
    },
    handleSelectionChange(val) {
      this.chooseList = val;
    },
    rowClick(row, col, event) {
      this.$refs.table.toggleRowSelection(row);
      this.dialogCheck(this.chooseList);
    },
    dialogCheck(res) {
      console.log(res);
    },
    // 批量删除
    bechChange() {
      if (this.chooseList.length == 0) {
        this.$message({
          duration: 5000,
          message: "请选择需要删除的列表!",
          type: "warning",
        });
        return;
      }
      let ids = "";
      this.chooseList.forEach((item) => {
        ids += item.id + ",";
      });
      if (ids.length > 0) {
        ids = ids.substr(0, ids.length - 1);
      }
      console.log(ids);
      let parms = {
        ids: ids,
      };
      this.plsc = parms;
      this.pldeleteVisible = true;
      this.todelete = "";
    },
    psdeleteSure() {
      if (this.todelete != "yes") {
        this.$message({
          duration: 5000,
          message: "请输入指示！",
          center: true,
        });
        return;
      }
      let loadingInstance = this.$loading({ text: "加载中" });
      postRequest("deleteHotelRoomBatch", this.plsc).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.getRoom();
          this.$message({
            duration: 5000,
            message: "删除成功!",
            type: "success",
          });
          this.pldeleteVisible = false;
          this.getFounderList();
          let room = this.$store.state.roomList;
          room.forEach((item, index) => {
            this.chooseList.forEach((item2) => {
              if (item.id == item2.id) {
                room.splice(index, 1);
              }
            });
          });
          this.$store.state.roomList = room;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getRoom() {
      postRequest("/selectAllRoomStatus", {}).then((res) => {
        if (res.status == 200) {
          res.data.forEach((item) => {
            item.choose = false;
            console.log(444, item);
            let allFlag = 0;
            let list = [];
            item.serviceStatusList.forEach((item2) => {
              console.log(item2.valueCode);
              if (item2.valueCode == "TurnOn") {
                list.push(item2);
              }
            });
            if (list.length > 0) {
              allFlag = 1;
            } else {
              allFlag = 0;
            }
            item.Flag = allFlag;
          });
          localStorage.setItem("roomList", JSON.stringify(res.data));
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    getRoomTypeList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {};
      postRequest("selectAllHotelType", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomTypeList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getRoomAreaList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {};
      postRequest("selectAllHotelArea", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomAreaList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getFoorList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        num: 100000,
        offset: 1,
      };
      postRequest("selectAllHotelFloor", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.foorList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    reSearch() {
      this.condition = { offset: 1, num: 10 };
      this.getFounderList();
    },
    // 获取创始人列表
    getFounderList() {
      // if (this.$store.state.roomTableList.length > 0) {
      //   this.tableData = this.$store.state.roomTableList;
      //   this.totolNums = this.$store.state.roomTableTotal;
      // } else {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllHotelRoom", this.condition).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.tableData = res.data.list;
          this.totolNums = res.data.total;
          this.$store.state.roomTableList = res.data.list;
          this.$store.state.roomTableTotal = res.data.total;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
      // }
    },
    // 删除
    del(row) {
      this.deleteVisible = true;
      this.todelete = "";
      this.row = row;
      return;
    },
    deleteSure() {
      if (this.todelete != "yes") {
        this.$message({
          duration: 5000,
          message: "请输入指示！",
          center: true,
        });
        return;
      }
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        id: this.row.id,
      };
      postRequest("deleteHotelRoom", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.getRoom();
          this.$message({
            duration: 5000,
            message: "删除成功!",
            type: "success",
          });
          this.deleteVisible = false;
          this.getFounderList();
          let room = this.$store.state.roomList;
          room.forEach((item, index) => {
            if (item.id == this.row.id) {
              room.splice(index, 1);
            }
          });
          this.$store.state.roomList = room;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    // 禁用
    toDisable(index, row) {
      let state = "禁用";
      this.$refs.disableModify.show(state, row, index);
    },
    setMange(row) {
      this.$router.push({
        name: "roomManage",
        query: row,
      });
    },
    // 新增
    addIdType() {
      let state = "创建";
      this.$refs.addModify.show(state);
    },
    xdList(row) {
      if (this.xiaodu) {
        this.$refs.disableModify.show(row);
      }
    },

    // 修改
    edit(index, row) {
      console.log(index);
      let state = "编辑";
      this.$refs.addModify.show(state, row, index);
    },
    // 每页的数据数
    onPaginationSize(Sizeval) {
      this.condition.num = Sizeval;
      this.getFounderList();
    },
    // 页数
    onPaginationIndex(Indexpage) {
      this.condition.offset = Indexpage;
      this.getFounderList();
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.outer {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button {
  width: 60px;
  height: 30px;
  /* background:rgb(163, 197, 248) ; */
  background-color: cornflowerblue;
  color: white;
  border: none;
}
.button2 {
  background-color: white;
  border: 1px solid #999;
  color: #666;
}
.hl-header {
  width: 100%;
  height: 60px;
  display: flex;
}
.hl-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  position: relative;
}
.hl-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
}
.el-card {
  position: relative;
}
.addIdType {
  width: 136px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.hl-search .span {
  width: 40px;
  height: 30px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 14px;
  border: none;
}
.hl-content {
  flex: 1;
  background-color: #fff;
  padding: 21px 32px;
  position: relative;
  overflow: scroll;
}
.mokuai {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  opacity: 0.85;
  margin-bottom: 21px;
}
.hl-table {
  /* background: red; */
  overflow: auto;
}
.cz-button {
  width: 74px;
  height: 28px;
  background: #6ec543;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
}
.qidong {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
}
.line {
  display: inline-block;
  height: 14px;
  border: 1px solid #e8ecef;
}
.cz {
  display: flex;
}
.qiyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #387dff;
  border-radius: 50%;
  margin-right: 6px;
}
.jinyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 50%;
  margin-right: 6px;
}
.zt > div {
  display: flex;
  align-items: center;
}
.addIdType2 {
  width: 100px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.dialog-footer > .el-button {
  width: 60px;
  height: 30px;
}
.xdNum {
  cursor: pointer;
  color: #409eff;
}
.addIdType3 {
  width: 80px;
  height: 40px;
  color: #fff;
  background: rgb(151, 151, 151);
  border: rgb(151, 151, 151);
}
.el-table {
  height: 90%;
  overflow: hidden;
}
.block {
  position: absolute;
  bottom: 10px;
  right: 32px;
}
.searchAll {
  margin-bottom: 20px;
}
.copy {
  cursor: pointer;
}
</style>