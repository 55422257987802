<template>
  <div>
    <el-dialog
      title="小度列表"
      :visible.sync="showing"
      width="50%"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <el-button
          v-if="xinzeng"
          class="add"
          type="primary"
          @click="addIdType()"
          >新增</el-button
        >
        <el-table :data="xdList" style="width: 100%">
          <el-table-column label="小度设备序列号">
            <template slot-scope="scope">
              <span class="copy" @click="copyMac(scope.row.xiaoduSn)">{{
                scope.row.xiaoduSn
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <div class="cz">
                <div class="qidong">
                  <el-button
                    v-if="shanchu"
                    style="color: red"
                    type="text"
                    @click="del(scope.row)"
                    >删除</el-button
                  >
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 新增 -->
    <el-dialog title="提示" :visible.sync="addVisible" width="30%">
      <el-form
        ref="formInline"
        :model="formInline"
        @submit.native.prevent
        :rules="rules"
      >
        <el-form-item label="小度序列号" prop="deviceType">
          <el-input
            placeholder="请输入小度序列号"
            v-model="formInline.xiaoduSn"
            ref="xiaoduSn"
            style="width: 100%"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="addSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除 -->
    <el-dialog title="提示" :visible.sync="deleteVisible" width="30%">
      <el-input
        placeholder="请输入删除指示"
        v-model="todelete"
        style="width: 100%"
        maxlength="10"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getRequest, postRequest, deleteRequest } from "@/api/api.js";
import { regMatch } from "../../utils/reg";
export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      addVisible: false,
      deleteVisible: false,
      xinzeng: false,
      shanchu: false,
      index: null,
      id: null,
      xdList: [],
      todelete: "",
      row: {},
      //表单
      formInline: {
        statusRemark: "",
      },
      //表单验证
      rules: {
        statusRemark: [
          {
            required: true,
            message: "请输入禁用说明，不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  methods: {
    copyMac(data) {
      let OrderNumber = data;
      let newInput = document.createElement("input");
      newInput.value = OrderNumber;
      document.body.appendChild(newInput);
      newInput.select();
      document.execCommand("Copy");
      newInput.remove();
      this.$message({
        type: "success",
        message: "复制成功",
      });
    },
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$refs.formInline.resetFields(); //清空表单
    },
    show(item) {
      this.xinzeng = this.power.checkedpower("0305008");
      this.shanchu = this.power.checkedpower("0305009");
      this.showing = true;
      this.formInline = {};
      this.id = item.id;
      this.getList();
    },
    // 获取列表
    getList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      getRequest("/selectHotelRoomXiaodu?roomId=" + this.id).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          this.xdList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
    addIdType() {
      this.addVisible = true;
      this.formInline.xiaoduSn = "";
    },
    addSure() {
      if (!regMatch(this.formInline.xiaoduSn, "小度序列号")) {
        this.$refs.xiaoduSn.focus();
        return;
      }
      let parms = {
        xiaoduSn: this.formInline.xiaoduSn,
        roomId: this.id,
      };
      postRequest("/insertHotelRoomXiaodu", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "新增成功!",
            type: "success",
          });
          this.addVisible = false;
          this.getList();
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
    // 删除
    del(row) {
      this.deleteVisible = true;
      this.todelete = "";
      this.row = row;
    },
    deleteSure() {
      if (this.todelete != "yes") {
        this.$message({
          duration: 5000,
          message: "请输入指示！",
          center: true,
        });
        return;
      }
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据

      deleteRequest("deleteHotelRoomXiaodu?id=" + this.row.id).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "删除成功!",
            type: "success",
          });
          this.deleteVisible = false;
          this.getList();
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    Password(formData) {
      // 表单验证-----------

      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parmst = {
        versionCode: this.formInline.versionCode,
        versionName: this.formInline.versionName,
        statusRemark: this.formInline.statusRemark, //禁用说明 必须
        status: 1, //状态
      };
      postRequest("/updateSysWechatStatus", parmst).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "禁用成功!",
            type: "success",
          });
          this.hide();
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.add {
  width: 100px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.banbentwo {
  width: 100%;
}
.banbentwoItem {
  width: 100%;
  margin-bottom: 16px;
}
.banbentwoItem > textarea {
  width: 100%;
  height: 170px !important;
  background: #fefeff;
  box-sizing: border-box;
  border: 1px solid #e8ecef;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
  padding-top: 10px;
}
textarea::-webkit-input-placeholder {
  color: #000000;
  opacity: 0.25;
}
.banbentwoItem > div:first-child {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
}
.copy {
  cursor: pointer;
}
</style>