<template>
  <div>
    <el-dialog
      :title="title + '房间管理'"
      :visible.sync="showing"
      width="550px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 一个表单 -->
        <el-form
          ref="formInline"
          :model="formInline"
          @submit.native.prevent
          :rules="rules"
        >
          <div class="banben">
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="酒店" prop="hotelId">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="formInline.hotelId"
                  placeholder="请选择酒店"
                  :disabled="hotel"
                >
                  <el-option
                    v-for="item in hotelList"
                    :key="item.id"
                    :label="item.hotelName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="房型" prop="roomType">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="formInline.roomType"
                  placeholder="请选择房型"
                  :disabled="hotelId"
                >
                  <el-option
                    v-for="item in roomTypeList"
                    :key="item.id"
                    :label="item.typeName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="banben">
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="区域" prop="area">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="formInline.area"
                  placeholder="请选择区域"
                >
                  <el-option
                    v-for="item in roomAreaList"
                    :key="item.id"
                    :label="item.areaName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="楼层" prop="foor">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="formInline.foor"
                  placeholder="请选择楼层"
                >
                  <el-option
                    v-for="item in foorList"
                    :key="item.id"
                    :label="item.floorName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>

          <div class="banben">
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="房号" prop="roomNo" style="width: 100%">
                <el-input
                  placeholder="请输入房号"
                  v-model="formInline.roomNo"
                  style="width: 100%"
                  maxlength="10"
                ></el-input>
              </el-form-item>
            </div>
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="入住状态" prop="roomStatus">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="formInline.roomStatus"
                  placeholder="请选择入住状态"
                >
                  <el-option
                    v-for="item in roomStatusList"
                    :key="item.statusDesc"
                    :label="item.roomStatus"
                    :value="item.statusDesc"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="bandanItem">
            <el-form-item label="MAC地址" prop="routerUrl" style="width: 100%">
              <el-input
                placeholder="请输入MAC地址"
                v-model="formInline.routerUrl"
                onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                style="width: 100%"
                maxlength="12"
                @input="macChange"
                :disabled="title == '编辑'"
              ></el-input>
            </el-form-item>
          </div>
          <div class="adressFalse" v-if="adressNo">
            请输入0-9，a-f,A-F等字符！
          </div>
          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="createStorage('formInline')"
              >确定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { createInsertUser, updateInsertUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";

export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      hotel: true,
      hotelId: false,
      index: null,
      bjIndex: null,
      //表单
      formInline: {
        roomStatus: "VC",
      },
      hotelList: [],
      roomTypeList: [],
      roomStatusList: [],
      roomAreaList: [],
      foorList: [],
      deviceList: [],
      DeviceList: [],
      adressNo: false,
      //表单验证
      rules: {
        roomType: [
          {
            required: true,
            message: "请选择房型",
            trigger: "blur",
          },
        ],
        area: [
          {
            required: true,
            message: "请选择区域",
            trigger: "blur",
          },
        ],
        foor: [
          {
            required: true,
            message: "请选择楼层",
            trigger: "blur",
          },
        ],
        roomStatus: [
          {
            required: true,
            message: "请选择入住状态",
            trigger: "blur",
          },
        ],
        roomNo: [
          {
            required: true,
            message: "请输入房号",
            trigger: "blur",
          },
        ],
        routerUrl: [
          {
            required: true,
            message: "请输入MAC地址",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  watch: {},
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$refs.formInline.resetFields(); //清空表单
    },
    show(item, val, index) {
      this.bjIndex = index;
      this.adressNo = false;
      this.formInline = {
        roomStatus: "VC",
      };
      this.hotelId = false;
      let hotelInfor = JSON.parse(window.localStorage.getItem("hotelInfor"));
      this.formInline.hotelId = Number(hotelInfor.hotelId);
      this.getHotelList();
      this.getRoomTypeList();
      this.getRoomAreaList();
      this.getFoorList();
      this.getRoomStatusList();
      // this.getDeviceList();
      // this.getdeviceList();
      this.title = item;
      this.showing = true;
      this.bedisabled = false;
      if (item == "编辑") {
        this.hotelId = true;
        this.bedisabled = true;
        this.formInline = JSON.parse(JSON.stringify(val));
        this.formInline.ioType = Number(this.formInline.ioType);
        this.formInline.display = Number(this.formInline.display);

        // this.formInline.roomStatus = Number(this.formInline.roomStatus);

        console.log(this.formInline);
      }
    },
    getRoomAreaList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {};
      postRequest("selectAllHotelArea", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomAreaList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getFoorList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        num: 100000,
        offset: 1,
      };
      postRequest("selectAllHotelFloor", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.foorList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getdeviceList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllHotelDeviceDefinition", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.DeviceList = res.data;
          console.log(345, this.deviceList);
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getHotelList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        offset: 1,
        num: 10000,
      };
      postRequest("selectAllHotel", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.hotelList = res.data.list;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getRoomTypeList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {};
      postRequest("selectAllHotelType", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomTypeList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getDeviceList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysDeviceType", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.deviceList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getRoomStatusList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysRoomStatus", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomStatusList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    createStorage(formData) {
      // 表单验证-----------

      // 新增
      this.$refs[formData].validate((valid) => {
        if (valid) {
          if (this.formInline.routerUrl.length < 12) {
            this.$message({
              duration: 5000,
              message: "MAC地址必须12位!",
              type: "warning",
            });
            return;
          }
          var patt = /^[A-Fa-f0-9_-]+$/;
          if (!patt.test(this.formInline.routerUrl)) {
            this.adressNo = true;
            this.$message({
              duration: 5000,
              message: "MAC地址不正确！",
              type: "warning",
            });
            this.formInline.routerUrl = "";
            return;
          } else {
            this.adressNo = false;
          }
          this.formInline.routerUrl = this.formInline.routerUrl.toUpperCase();
          if (this.title == "创建") {
            let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
            postRequest("/insertHotelRoom", this.formInline).then((res) => {
              this.$nextTick(() => {
                loadingInstance.close();
              }); //不回调
              if (res.status == 200) {
                this.getRoom();
                this.$message({
                  duration: 5000,
                  message: "创建成功!",
                  type: "success",
                });
                this.$store.state.roomList.push(res.data);
                this.hide();
                this.$refs.formInline.resetFields(); //清空表单
                this.$parent.getFounderList();
              } else {
                this.$message({
                  duration: 5000,
                  message: res.message,
                  type: "warning",
                });
              }
            });
          } else {
            this.updateStation(); //调用编辑函数
          }
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning",
          });
        }
      });
    },
    // 编辑
    updateStation() {
      postRequest("/updateHotelRoom", this.formInline).then((res) => {
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "编辑成功!",
            type: "success",
          });
          this.hide();
          this.$refs.formInline.resetFields(); //清空表单
          this.$parent.getFounderList();
          let room = this.$store.state.roomList;
          room.forEach((item, index) => {
            if (item.id == this.formInline.id) {
              res.data.serviceStatusList = item.serviceStatusList;
              room.splice(index, 1, res.data);
            }
          });
          this.$store.state.roomList = room;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    getRoom() {
      postRequest("/selectAllRoomStatus", {}).then((res) => {
        if (res.status == 200) {
          res.data.forEach((item) => {
            item.choose = false;
            console.log(444, item);
            let allFlag = 0;
            let list = [];
            item.serviceStatusList.forEach((item2) => {
              console.log(item2.valueCode);
              if (item2.valueCode == "TurnOn") {
                list.push(item2);
              }
            });
            if (list.length > 0) {
              allFlag = 1;
            } else {
              allFlag = 0;
            }
            item.Flag = allFlag;
          });
          localStorage.setItem("roomList", JSON.stringify(res.data));
          console.log(9998, res.data);
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    //附件
    //上传附件----------------------------------------------
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      let isFlag = true;
      if (!isLt5M) {
        this.$message.error("上传头像文件大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //--------------------------------------------------------------------
    // 成功之后的返回
    handleAvatarSuccess(res, file) {
      this.fileJson.push(res.data);
      this.fileListUpload.push(res.data);
    },

    // 删除之后的返回值
    handleRemove(file, fileList) {
      this.fileJson.forEach((fileId, index) => {
        if (fileId.id == file.id) {
          this.fileJson.splice(index, 1);
          this.fileListUpload.splice(index, 1);
        }
      });
    },
    //预览
    handlePreview(file) {
      let fileName = file.fileName;
      //获取最后一个.的位置
      let index = fileName.lastIndexOf(".");
      //获取后缀
      let ext = fileName.substr(index + 1);
      if (this.isAssetTypeAnImage(ext)) {
        //图片预览
        window.open(
          window.string.BUSINESS_PUBLIC_API_URL +
            "/sys/attachments/getImageByStream/?id=" +
            file.id
        );
      } else {
        //永中预览
        previewUpload(file.id).then(({ data, result, message }) => {
          if (result == 1) {
            var previewUrl = data.filePath;
            window.open(window.string.PUBLIC_VIEW_FILE_API + previewUrl);
          } else {
            this.$message({
              duration: 5000,
              message: message,
              type: "warning",
            });
          }
        });
      }
    },
    isAssetTypeAnImage(ext) {
      return (
        ["png", "jpg", "jpeg", "bmp", "gif"].indexOf(ext.toLowerCase()) !== -1
      );
    },
    handleDowload(file) {
      window.location.href =
        window.string.BUSINESS_PUBLIC_API_URL +
        "/sys/attachments/donwload/?id=" +
        file.id;
    },
    macChange(val) {
      // console.log(val);
      var patt = /^[A-Fa-f0-9_-]+$/;
      if (!patt.test(val)) {
        this.adressNo = true;
        this.$message({
          duration: 5000,
          message: "MAC地址不正确！",
          type: "warning",
        });
      } else {
        this.adressNo = false;
      }
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.el-button {
  width: 200px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
.adressFalse {
  color: red;
}
</style>